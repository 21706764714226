<template>
  <div class="sidebar">
    <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router>
      <el-menu-item :index="h.index" v-for="h in home" :key="h.index">
        <i :class="h.icon"></i>
        <template #title>{{ h.title }}</template>
      </el-menu-item>
      <el-submenu :index="item.index" v-for="item in route" :key="item.index">
        <template #title>
          <i :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item
            :index="sub.index"
            v-for="sub in item.subs"
            :key="sub.index">
          {{ sub.title }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: [],
      home: [],
      items: [
        {
          icon: "el-icon-lx-home",
          index: "/dashboard",
          title: "首页",
        },
        {
          icon: "el-icon-lx-home",
          index: "/dashboard2",
          title: "首页",
        },
        {
          icon: "el-icon-lx-read",
          index: "/bookManagement",
          title: "图书管理",
          subs: [
            {
              index: "/bookfile",
              title: "图书档案",
            },
            {
              index: "/bookcatalog",
              title: "图书编目",
            },
            {
              index: "/bookputon",
              title: "图书上架",
            },
            {
              index: "/library",
              title: "书屋管理",
            },
            {
              index: "/bookshelf",
              title: "书架管理",
            },
            {
              index: "/classification",
              title: "分类管理",
            },
            {
              index: "/errorManager",
              title: "报错管理",
            },
          ],
        },
        {
          icon: "el-icon-lx-copy",
          index: "/journal",
          title: "期刊管理",
          subs: [
            {
              index: "/journalsubscribe",
              title: "期刊订阅",
            },
            {
              index: "/journalregister",
              title: "期刊登记",
            },
            {
              index: "/journaltype",
              title: "期刊类型",
            },
            // {
            //   index: "/journalcirculation",
            //   title: "JournalCirculation",
            // },
          ],
        },
        {
          icon: "el-icon-refresh",
          index: "/allcirculation",
          title: "书刊流通",
          subs: [
            {
              index: "/fastborrowandreturn",
              title: "快速借还"
            },
            {
              index: "/circulation",
              title: "书刊流通",
            },
            {
              index: "/renew",
              title: "书刊续借",
            },
            {
              index: "/survey",
              title: "流通概况",
            },
            {
              index: "/overdue",
              title: "超期罚款",
            },
            {
              index: "/proofreading",
              title: "图书校对",
            },
            {
              index: "/lostandbreaklist",
              title: "书刊丢失损毁登记",
            },
            {
              index: "/lostandbreakregister",
              title: "书刊丢失损毁阅览",
            },
          ],
        },
        {
          icon: "el-icon-lx-friend",
          index: "/reader",
          title: "读者管理",
          subs: [
            {
              index: "/readerfile",
              title: "读者档案",
            },
            {
              index: "/type",
              title: "类型管理",
            },
            // {
            //   index: "readerregister",
            //   title: "读者证办理"
            // },
            // {
            //   index: "/unit",
            //   title: "单元管理",
            // },
            {
              index: "/grade",
              title: "部门管理",
            },
            {
              index: "/class",
              title: "小组管理",
            },
          ],
        },
        {
          icon: "el-icon-pie-chart",
          index: "/charts",
          title: "报表统计",
          subs: [
            {
              index: "/readerlendranking",
              title: "读者借阅排行",
            },
            // {
            //   index: "readerlendmsg",
            //   title: "读者借阅信息"
            // },
            {
              index: "/booklendranking",
              title: "图书借阅排行",
            },
            // {
            //   index: "permission",
            //   title: "到期图书统计"
            // },
            // {
            //   index: "permission",
            //   title: "图书异常统计"
            // },
            {
              index: "/librarychangedmsg",
              title: "图书位置变更日志",
            },
            {
              index: "/classifiedstatistics",
              title: "图书分类统计",
            },
            {
              index: "/bookmsgstatistics",
              title: "图书信息统计",
            },
            // {
            //   index: "permission",
            //   title: "图书超期罚款"
            // },
            {
              index: "/schoolranking",
              title: "区域学校排行",
            },
            {
              index: "/workloadstatistics",
              title: "工作量统计",
            },
            {
              index: "/integralstatistics",
              title: "积分统计",
            },
          ],
        },
        {
          icon: "el-icon-lx-edit",
          index: "/7",
          title: "数据管理",
          subs: [
            {
              index: "/readerimport",
              title: "读者信息导入",
            },
            {
              index: "/bookimport",
              title: "图书信息导入",
            },
            {
              index: "/circulationimport",
              title: "借阅信息导入",
            },
            // {
            //   index: "/circulationimport",
            //   title: "历史借阅信息导入",
            // },
          ],
        },
        {
          icon: "el-icon-lx-settings",
          index: "/donate",
          title: "系统设置",
          subs: [
            {
              index: "/school",
              title: "组织管理",
            },
            {
              index: "/user",
              title: "用户管理",
            },
            {
              index: "/config",
              title: "系统设置",
            },
            {
              index: "/holiday",
              title: "假期管理",
            },
            {
              index: "/image",
              title: "封面管理",
            },
            {
              index: "/announcement",
              title: "公告管理",
            },
            {
              index: "/publicize",
              title: "宣传栏管理",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getroute();
  },
  methods: {
    getroute() {
      //json格式化
      var rou = JSON.parse(localStorage.getItem("route"));
      var system = rou.System
      //权限这里改变    System只需要管首页
      for (var i in system) {
        if (system[i].StrName === '首页' && system[i].Selected) {
          if (localStorage.getItem('IsAreaManager') === 'true') {
            this.home.push(this.items[1])
          } else {
            this.home.push(this.items[0])
          }
        }
      }
      for (var j in this.items) {
          // eslint-disable-next-line no-redeclare
        for (var i in rou) {
          //一级标题对上了
          if (i === this.items[j].title) {
            //添加父亲路由
            const param = {
              icon: this.items[j].icon,
              index: this.items[j].index,
              title: this.items[j].title,
              subs: [],
            };
            this.route.push(param);
            //判断子路由是否存在，不存在则删除父路由
            var isexist = false;
            for (var w in this.items[j].subs) {
              for (var k in rou[i]) {
                if (rou[i][k].Selected) {
                  isexist = true
                  if (this.items[j].subs[w].title === rou[i][k].StrName) {
                    this.route[this.route.length - 1].subs.push(this.items[j].subs[w])
                  }
                }
              }
            }
            if(localStorage.getItem('username')==='系统管理员'&&this.route.length===1){
              this.route[this.route.length - 1].subs.push({
                index: "/errorManager",
                title: "报错管理",
              })
            }
            if (!isexist) {
              this.route.pop()
            }
          }
        }
      }
    },
  },
  computed: {
    onRoutes() {
      return this.$route.path;
      // return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}

.subs {
  background-color: #ffffff !important;
}

.sidebar > ul {
  height: 100%;
}
</style>
